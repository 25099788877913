import { useStorage } from "@vueuse/core";
import type { ComputedRef } from "vue";

import type { Social, InitDataResponseData } from "@/types";

export const socials = [
	{
		id: "gp",
		name: "google",
		href: "/login/Google/",
		icon: "google",
		type: "googleAuthEnabled"
	},
	{
		id: "fb",
		name: "facebook",
		href: "/login/facebook/",
		icon: "fb",
		type: "facebookAuthEnabled"
	}
] as const;

const useAuthorizationSocialButtons = (
	enabledButtons: ComputedRef<NonNullable<InitDataResponseData>["registrationFormData"]>
) => {
	const promoOfferStorage = useStorage("promoOffer", "");
	const route = useRoute();
	const buttons = computed(() => socials.filter(({ type }) => !!enabledButtons.value[type]));
	const handleButtonClick = (_social: Social) => {
		const { promoOffer } = route.query;

		if (promoOffer && !Array.isArray(promoOffer)) {
			promoOfferStorage.value = promoOffer;
		}
	};
	return {
		buttons,
		handleButtonClick
	};
};

export default useAuthorizationSocialButtons;
